<template>
  <div v-if="!pendaftar" class="h3 text-center text-secondary">Data pembayaran tidak ditemukan</div>
  <div v-else-if="page == 1" id="bukti-pembayaran">
    <img v-for="i in 3" :key="i" :class="'bg-img bg-img' + i" src="../assets/bg-bukti.png" alt="">
    <div class="container-fluid px-2">
      <div class="row px-3 py-2">
        <div class="col-10 text-center d-flex px-1">
          <div class="col-2 d-flex">
            <div class="m-auto text-center">
              <img class="w-100" src="../assets/logo.png" alt="">
            </div>
          </div>
          <div class="col-10 text-center d-flex px-1">
            <div class="m-auto">
              <h5 class="mb-0">
                PENERIMAAN PESERTA DIDIK BARU SMK PGRI 1 KEDIRI<br />
                TAHUN AJARAN {{ pendaftar.nama_tahun_ajaran }}
              </h5>
              <hr class="kop my-1">
              <span id="h-pembayaran" class="h6 mb-0">TANDA BUKTI PEMBAYARAN</span>
            </div>
          </div>
        </div>
        <div class="col-2 d-flex border border-2 border-dark rounded-3">
          <div class="m-auto text-center">
            <h3 class="mb-0">{{ pendaftar.status_ppdb_id == 4 ? 'L1' : 'F2' }}</h3>
            <hr class="my-0 opacity-100">
            <h6>Panitia</h6>
          </div>
        </div>
      </div>
      <div class="px-5 py-1 small">
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Telah Terima Dari</span><span>:</span>
            </div>
          </div>
          <div class="col-8 fw-bold ps-1">{{ pendaftar.nama }}</div>
        </div>
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Nomor Registrasi</span><span>:</span>
            </div>
          </div>
          <div class="col-8 fw-bold ps-1">{{ pendaftar.no_reg }}</div>
        </div>
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Jurusan/Konsentrasi Keahlian</span><span>:</span>
            </div>
          </div>
          <div id="jurusan" class="col-8 fw-bold ps-1">{{ pendaftar.nama_jurusan + " (" + pendaftar.kode + ")" }}
          </div>
        </div>
        <hr class="baris">
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Banyaknya Uang</span><span>:</span>
            </div>
          </div>
          <div class="col-8 fw-bold ps-1 d-inline-flex gap-1">
            <h4 class="my-0">{{ Number(pendaftar.nominal).toLocaleString(
    "id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  }) }}</h4>
            <span class="fst-italic fw-normal my-auto">~ {{ pendaftar.terbilang }} Rupiah</span>
          </div>
        </div>
        <hr class="baris">
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Untuk Pembayaran</span><span>:</span>
            </div>
          </div>
          <div class="col-8 fw-bold ps-1">Daftar Ulang PPDB SMK PGRI 1 KEDIRI {{ pendaftar.nama_tahun_ajaran }}</div>
        </div>
        <hr class="baris">
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Waktu Pembayaran</span><span>:</span>
            </div>
          </div>
          <div class="col-8 ps-1">{{ pendaftar.created_at }} ({{ pendaftar.creator }})
            <span id="judul" class="fst-italic" v-if="pendaftar.editor">, diperbarui
              {{ pendaftar.updated_at }} ({{ pendaftar.editor }})</span>
          </div>
        </div>
        <hr class="baris">
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Biaya Daftar Ulang</span><span>:</span>
            </div>
          </div>
          <div class="col-8 ps-1">
            <strong>{{ Number(biaya_du).toLocaleString(
    "id-ID",
    {
      style: "currency",
      currency: "IDR",
      maximumFractionDigits: 0,
    }) }}</strong>
            <span v-if="voucher" class="fst-italic"> (dengan voucher)</span>
            <span v-else-if="pendaftar.jalur_ppdb_id !== 1" class="fst-italic"> (Jalur {{ pendaftar.jalur_pendaftaran
              }})</span>
          </div>
        </div>
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Pembayaran Sebelumnya</span><span>:</span>
            </div>
          </div>
          <div class="col-8 ps-1">
            <div v-if="dataBayar.length">
              <div v-for="b, index in dataBayar" :key="b">{{ index+=1 }}. {{ b.created_at }} - <strong>{{
    Number(b.nominal).toLocaleString(
      "id-ID",
      {
        style: "currency",
        currency: "IDR",
        maximumFractionDigits: 0,
      }) }}</strong>
              </div>
            </div>
            <div v-else>-</div>
          </div>
        </div>
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Total Pembayaran</span><span>:</span>
            </div>
          </div>
          <div class="col-8 ps-1">
            <strong>{{ Number(totalPembayaran).toLocaleString(
    "id-ID",
    {
      style: "currency",
      currency: "IDR",
      maximumFractionDigits: 0,
    }) }}</strong>
            <span v-if="kekurangan > 0" class="fst-italic text-danger"> (kekurangan {{
    Number(kekurangan).toLocaleString(
      "id-ID",
      {
        style: "currency",
        currency: "IDR",
        maximumFractionDigits: 0,
      }) }})</span>
          </div>
        </div>
        <hr class="baris">
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Catatan</span><span>:</span>
            </div>
          </div>
          <div class="col-8 ps-1">
            <div v-if="pendaftar.status_ppdb_id == 4" class="fw-bold" id="lunas">
              {{ pendaftar.status_pendaftaran }}</div>
            <div v-else class="fst-italic">Pembayaran terakhir tanggal 12 Juli 2024</div>
          </div>
        </div>
        <div class="d-flex row row-cols-3 text-center small mt-4">
          <div class="col-4 mt-auto">
            <div class="mb-0">Yang Menyerahkan,</div>
            <div class="mb-5">Peserta Didik Baru</div>
            <div class="fw-bold text-decoration-underline">{{ pendaftar.nama }}</div>
          </div>
          <div class="col-4 mt-auto">
            <div class="mb-5">Petugas Verifikasi</div>
            <div class="fw-bold text-decoration-underline">{{ user.nama }}</div>
          </div>
          <div class="col-4 mt-auto">
            Kediri, {{ new Date().toLocaleDateString("id-ID", { dateStyle: "long" }) }}
            <div class="mb-0">Yang Menerima,</div>
            <div class="mb-5">Petugas Keuangan</div>
            <div class="fw-bold text-decoration-underline">{{ pendaftar.nama_petugas_keuangan }}</div>
          </div>
        </div>
      </div>
    </div>
    <hr class="batas mb-0">
    <div class="container-fluid px-2">
      <div class="row px-3 py-2">
        <div class="col-10 text-center d-flex px-2">
          <div class="col-2 d-flex">
            <div class="m-auto text-center">
              <img class="w-100" src="../assets/logo.png" alt="">
            </div>
          </div>
          <div class="col-10 text-center d-flex px-1">
            <div class="m-auto">
              <h5 class="mb-0">
                PENERIMAAN PESERTA DIDIK BARU SMK PGRI 1 KEDIRI<br />
                TAHUN AJARAN {{ pendaftar.nama_tahun_ajaran }}
              </h5>
              <hr class="kop my-1">
              <span id="h-pembayaran" class="h6 mb-0">TANDA BUKTI PEMBAYARAN</span>
            </div>
          </div>
        </div>
        <div class="col-2 d-flex border border-2 border-dark rounded-3">
          <div class="m-auto text-center">
            <h3 class="mb-0">{{ pendaftar.status_ppdb_id == 4 ? 'L1' : 'F2' }}</h3>
            <hr class="my-0 opacity-100">
            <h6>Peserta</h6>
          </div>
        </div>
      </div>
      <div class="px-5 py-1 small">
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Telah Terima Dari</span><span>:</span>
            </div>
          </div>
          <div class="col-8 fw-bold ps-1">{{ pendaftar.nama }}</div>
        </div>
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Nomor Registrasi</span><span>:</span>
            </div>
          </div>
          <div class="col-8 fw-bold ps-1">{{ pendaftar.no_reg }}</div>
        </div>
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Jurusan/Konsentrasi Keahlian</span><span>:</span>
            </div>
          </div>
          <div id="jurusan" class="col-8 fw-bold ps-1">{{ pendaftar.nama_jurusan + " (" + pendaftar.kode + ")" }}
          </div>
        </div>
        <hr class="baris">
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Banyaknya Uang</span><span>:</span>
            </div>
          </div>
          <div class="col-8 fw-bold ps-1 d-inline-flex gap-1">
            <h4 class="my-0">{{ Number(pendaftar.nominal).toLocaleString(
    "id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  }) }}</h4>
            <span class="fst-italic fw-normal my-auto">~ {{ pendaftar.terbilang }} Rupiah</span>
          </div>
        </div>
        <hr class="baris">
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Untuk Pembayaran</span><span>:</span>
            </div>
          </div>
          <div class="col-8 fw-bold ps-1">Daftar Ulang PPDB SMK PGRI 1 KEDIRI {{ pendaftar.nama_tahun_ajaran }}</div>
        </div>
        <hr class="baris">
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Waktu Pembayaran</span><span>:</span>
            </div>
          </div>
          <div class="col-8 ps-1">{{ pendaftar.created_at }}</div>
        </div>
        <hr class="baris">
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Biaya Daftar Ulang</span><span>:</span>
            </div>
          </div>
          <div class="col-8 ps-1">
            <strong>{{ Number(biaya_du).toLocaleString(
    "id-ID",
    {
      style: "currency",
      currency: "IDR",
      maximumFractionDigits: 0,
    }) }}</strong>
            <span v-if="voucher" class="fst-italic"> (dengan voucher)</span>
            <span v-else-if="pendaftar.jalur_ppdb_id !== 1" class="fst-italic"> (Jalur {{ pendaftar.jalur_pendaftaran
              }})</span>
          </div>
        </div>
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Pembayaran Sebelumnya</span><span>:</span>
            </div>
          </div>
          <div class="col-8 ps-1">
            <div v-if="dataBayar.length">
              <div v-for="b, index in dataBayar" :key="b">{{ index+=1 }}. {{ b.created_at }} - <strong>{{
    Number(b.nominal).toLocaleString(
      "id-ID",
      {
        style: "currency",
        currency: "IDR",
        maximumFractionDigits: 0,
      }) }}</strong>
              </div>
            </div>
            <div v-else>-</div>
          </div>
        </div>
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Total Pembayaran</span><span>:</span>
            </div>
          </div>
          <div class="col-8 ps-1">
            <strong>{{ Number(totalPembayaran).toLocaleString(
    "id-ID",
    {
      style: "currency",
      currency: "IDR",
      maximumFractionDigits: 0,
    }) }}</strong>
            <span v-if="kekurangan > 0" class="fst-italic text-danger"> (kekurangan {{
    Number(kekurangan).toLocaleString(
      "id-ID",
      {
        style: "currency",
        currency: "IDR",
        maximumFractionDigits: 0,
      }) }})</span>
          </div>
        </div>
        <hr class="baris">
        <div class="d-flex row row-cols-2 small">
          <div class="col-4 pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Catatan</span><span>:</span>
            </div>
          </div>
          <div class="col-8 ps-1">
            <div v-if="pendaftar.status_ppdb_id == 4" class="fw-bold" id="lunas">
              {{ pendaftar.status_pendaftaran }}</div>
            <div v-else class="fst-italic">Pembayaran terakhir tanggal 12 Juli 2024</div>
            <div class="small fst-italic text-danger">*) Biaya administrasi yang telah dibayarkan tidak bisa ditarik
              kembali.</div>
          </div>
        </div>
        <div class="d-flex row row-cols-3 text-center small mt-4">
          <div class="col-4 mt-auto">
            <div class="mb-0">Yang Menyerahkan,</div>
            <div class="mb-5">Peserta Didik Baru</div>
            <div class="fw-bold text-decoration-underline">{{ pendaftar.nama }}</div>
          </div>
          <div class="col-4 mt-auto">
            <div class="mb-5">Petugas Verifikasi</div>
            <div class="fw-bold text-decoration-underline">{{ user.nama }}</div>
          </div>
          <div class="col-4 mt-auto">
            Kediri, {{ new Date().toLocaleDateString("id-ID", { dateStyle: "long" }) }}
            <div class="mb-0">Yang Menerima,</div>
            <div class="mb-5">Petugas Keuangan</div>
            <div class="fw-bold text-decoration-underline">{{ pendaftar.nama_petugas_keuangan }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else id="bukti-pembayaran">
    <img v-for="i in 3" :key="i" :class="'bg-img bg-img' + i" src="../assets/bg-bukti.png" alt="">
    <div class="container-fluid px-2">
      <div class="row px-3 py-2">
        <div class="col-10 text-center d-flex px-1">
          <div class="col-2 d-flex">
            <div class="m-auto text-center">
              <img class="w-100" src="../assets/logo.png" alt="">
            </div>
          </div>
          <div class="col-10 text-center d-flex px-1">
            <div class="m-auto">
              <h5 class="mb-0">
                PENERIMAAN PESERTA DIDIK BARU SMK PGRI 1 KEDIRI<br />
                TAHUN AJARAN {{ pendaftar.nama_tahun_ajaran }}
              </h5>
              <hr class="kop my-1">
              <span id="h-seragam" class="h6 mb-0">BUKTI PENGAMBILAN SERAGAM</span>
            </div>
          </div>
        </div>
        <div class="col-2 d-flex border border-2 border-dark rounded-3">
          <div class="m-auto text-center">
            <h3 class="mb-0">F3</h3>
            <hr class="my-0 opacity-100">
            <h6>Panitia</h6>
          </div>
        </div>
      </div>
      <div class="px-2 small">
        <div class="d-flex row row-cols-2 small justify-content-center">
          <div class="col-3 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Nomor Registrasi</span><span>:</span>
            </div>
          </div>
          <div class="col-7 fw-bold ps-1">{{ pendaftar.no_reg }}</div>
        </div>
        <div class="d-flex row row-cols-2 small justify-content-center">
          <div class="col-3 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Nama Peserta Didik Baru</span><span>:</span>
            </div>
          </div>
          <div class="col-7 fw-bold ps-1">{{ pendaftar.nama }}</div>
        </div>
        <div class="d-flex row row-cols-2 small mb-2 justify-content-center">
          <div class="col-3 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Jurusan/Konsentrasi Keahlian</span><span>:</span>
            </div>
          </div>
          <div id="jurusan" class="col-7 fw-bold ps-1">{{ pendaftar.nama_jurusan + " (" + pendaftar.kode + ")" }}
          </div>
        </div>
        <div class="col-11 mx-auto">
          <table class="table table-sm table-bordered border-secondary small">
            <thead class="text-center small align-middle">
              <tr>
                <th style="width:4%">No.</th>
                <th>Jenis Seragam</th>
                <th style="width:13%">Tanggal Diterima</th>
                <th style="width:17%">Tanda Tangan Peserta</th>
                <th style="width:20%">Keterangan</th>
              </tr>
            </thead>
            <tbody class="small">
              <tr v-for="s, index in seragam" :key="s">
                <td class="text-center">{{ index+=1 }}</td>
                <td>{{ s }}</td>
                <td></td>
                <td :class="{ 'text-center': index % 2 == 0 }">{{ index }}.</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-4 ms-auto small">
          Kediri, {{ new Date().toLocaleDateString("id-ID", { dateStyle: "long" }) }}
          <div class="mb-5">Petugas Pendaftaran</div>
          <div class="fw-bold text-decoration-underline">{{ user.nama }}</div>
        </div>
      </div>
    </div>
    <hr class="batas mb-0">
    <div class="container-fluid px-2">
      <div class="row px-3 py-2">
        <div class="col-10 text-center d-flex px-1">
          <div class="col-2 d-flex">
            <div class="m-auto text-center">
              <img class="w-100" src="../assets/logo.png" alt="">
            </div>
          </div>
          <div class="col-10 text-center d-flex px-1">
            <div class="m-auto">
              <h5 class="mb-0">
                PENERIMAAN PESERTA DIDIK BARU SMK PGRI 1 KEDIRI<br />
                TAHUN AJARAN {{ pendaftar.nama_tahun_ajaran }}
              </h5>
              <hr class="kop my-1">
              <span id="h-seragam" class="h6 mb-0">BUKTI PENGAMBILAN SERAGAM</span>
            </div>
          </div>
        </div>
        <div class="col-2 d-flex border border-2 border-dark rounded-3">
          <div class="m-auto text-center">
            <h3 class="mb-0">F3</h3>
            <hr class="my-0 opacity-100">
            <h6>Peserta</h6>
          </div>
        </div>
      </div>
      <div class="px-2 small">
        <div class="d-flex row row-cols-2 small justify-content-center">
          <div class="col-3 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Nomor Registrasi</span><span>:</span>
            </div>
          </div>
          <div class="col-7 fw-bold ps-1">{{ pendaftar.no_reg }}</div>
        </div>
        <div class="d-flex row row-cols-2 small justify-content-center">
          <div class="col-3 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Nama Peserta Didik Baru</span><span>:</span>
            </div>
          </div>
          <div class="col-7 fw-bold ps-1">{{ pendaftar.nama }}</div>
        </div>
        <div class="d-flex row row-cols-2 small mb-2 justify-content-center">
          <div class="col-3 my-auto pe-0">
            <div class="d-flex justify-content-between gap-1 text-secondary">
              <span>Jurusan/Konsentrasi Keahlian</span><span>:</span>
            </div>
          </div>
          <div id="jurusan" class="col-7 fw-bold ps-1">{{ pendaftar.nama_jurusan + " (" + pendaftar.kode + ")" }}
          </div>
        </div>
        <div class="col-11 mx-auto">
          <table class="table table-sm table-bordered border-secondary small">
            <thead class="text-center small align-middle">
              <tr>
                <th style="width:4%">No.</th>
                <th>Jenis Seragam</th>
                <th style="width:13%">Tanggal Diterima</th>
                <th style="width:18%">Petugas Seragam</th>
                <th style="width:15%">Tanda Tangan</th>
                <th style="width:17%">Keterangan</th>
              </tr>
            </thead>
            <tbody class="small">
              <tr v-for="s, index in seragam" :key="s">
                <td class="text-center">{{ index+=1 }}</td>
                <td>{{ s }}</td>
                <td></td>
                <td></td>
                <td :class="{ 'text-center': index % 2 == 0 }">{{ index }}.</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BuktiPembayaran',
  data() {
    return {
      pendaftar: null,
      dataBayar: null,
      biaya_du: null,
      totalPembayaran: null,
      kekurangan: null,
      voucher: false,
//      seragam: [
//        '4 Kain Atasan (Putih, Khas, Pramuka, Batik PGRI)',
//        '3 Kain Celana (Abu-abu, Khas, Pramuka)',
//        '1 Setel Baju Olahraga',
//        '1 Setel Baju Praktik',
//        '1 Pasang Sepatu',
//        '1 Set Atribut (2 Set Pangkat, 3 Topi, 2 Dasi, 1 Halsduk)',
//        '1 Buah Ikat Pinggang',
//        '1 Set Label/Badge (2 Bendera, 1 Logo Sekolah, 2 Jurusan, 2 Lokasi, 1 OSIS, 1 Gugus Depan, 1 Jawa Timur, 1 Kota Kediri)'
//      ],
      seragam: [],
    }
  },
  props: {
    bayar: Object,
    page: Number,
  },
  computed: {
    user() {
      return this.$store.getters.getUserData;
    },
    token() {
      return this.$store.getters.getToken
    },
  },
  mounted() {
    this.pendaftar = this.bayar.pendaftar;
    this.dataBayar = this.bayar.riwayat;
    this.biaya_du = this.bayar.biaya_du;
    this.totalPembayaran = this.bayar.totalPembayaran;
    this.kekurangan = this.bayar.kekurangan;
    this.voucher = this.bayar.voucher;
    this.seragam = this.bayar.listSeragam;
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style scoped>
#bukti-pembayaran {
  position: relative;
  font-family: 'Trebuchet MS', sans-serif;
}

.bg-img {
  position: absolute;
  height: auto;
  opacity: 0.3;
  object-fit: cover;
  z-index: -1;
}

.bg-img1 {
  top: 0;
  left: 0;
  width: 60%;
}

.bg-img2 {
  bottom: 0;
  right: 0;
  width: 60%;
}

.bg-img3 {
  bottom: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 50%);
}

#h-pembayaran {
  color: #558000;
}

#h-seragam {
  color: #375995;
}

#lunas {
  color: #558000;
}

#jurusan {
  color: #375995;
}

hr.kop {
  height: 5px;
  border-top: 2px solid black;
  border-bottom: 1px solid black;
}

hr.batas {
  height: 2px;
  border-top: none;
  border-bottom: 2px dashed black;
}

hr.baris {
  height: 1px;
  border-top: none;
  border-bottom: 1px solid gray;
  padding: 0;
  margin: 2px;
}

table td,
table th {
  background: transparent;
  padding: 2px 4px;
}
</style>