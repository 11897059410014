<template>
  <div v-if="jenis === 'pendaftaran'" ref="myElement">
    <bukti-pendaftaran :pendaftar="dataPendaftar" />
  </div>
  <div v-else-if="jenis === 'pembayaran'" ref="myElement">
    <div v-if="pembayaranSiap">
      <bukti-pembayaran :bayar="dataBayar" :page="1" />
      <div v-if="dataBayar.seragam" style="break-after:page"></div>
      <div v-if="dataBayar.seragam">
        <bukti-pembayaran :bayar="dataBayar" :page="2" />
      </div>
    </div>
  </div>
  <div v-else-if="jenis === 'pernyataan'" ref="myElement">
    <surat-pernyataan :sp="pernyataan" />
  </div>
  <not-found v-else />
</template>

<script>
import NotFound from '@/components/NotFound'
import BuktiPendaftaran from '@/components/BuktiPendaftaran'
import BuktiPembayaran from '@/components/BuktiPembayaran'
import SuratPernyataan from '@/components/SuratPernyataan'
export default {
  name: 'CetakView',
  components: {
    NotFound,
    BuktiPendaftaran,
    BuktiPembayaran,
    SuratPernyataan
  },
  data() {
    return {
      memuat: null,
      jenis: this.$route.params.jenis,
      ppdb_id: this.$route.params.ppdb_id,
      sp_ppdb_id: this.$route.params.ppdb_id,
      bayar_ppdb_id: this.$route.params.bayar_ppdb_id,
      dataPendaftar: null,
      dataBayar: {
        pendaftar: null,
        riwayat: [],
        biaya_du: 0,
        totalPembayaran: 0,
        kekurangan: 0,
        voucher: false,
        seragam: false,
        listSeragam: [],
      },
      pembayaranSiap: false,
      pernyataan: null,
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    taAktif() {
      return this.$store.getters.getTahunAjaran
    },
  },
  mounted() {
    this.memuat = this.$loading.show({
      loader: 'spinner'
    });
    switch (this.jenis) {
      case 'pendaftaran':
        this.getDataPendaftarById();
        break;
      case 'pembayaran':
        this.getDataPembayaranById();
        break;
      case 'pernyataan':
        this.getDataPernyataanById();
        break;
      default:
        break;
    }
    setTimeout(() => {
      this.memuat.hide();
    }, 250);
  },
  methods: {
    async getDataPendaftarById() {
      try {
        const response = await this.axios.post('/data/pendaftaran', {
          ppdb_id: this.ppdb_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.dataPendaftar = response.data.pendaftar[0];
          this.dataPendaftar.tanggal_lahir = new Date(this.dataPendaftar.tanggal_lahir).toLocaleDateString('id-ID', { dateStyle: 'long' })
          this.dataPendaftar.created_at = new Date(this.dataPendaftar.created_at).toLocaleString('id-ID', { dateStyle: 'long', timeStyle: 'medium' })
          if (this.dataPendaftar.updated_at) {
            this.dataPendaftar.updated_at = new Date(this.dataPendaftar.updated_at).toLocaleString('id-ID', { dateStyle: 'long', timeStyle: 'medium' })
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        this.cetak();
      }, 500)
    },
    async getDataPembayaranById() {
      try {
        const response = await this.axios.post('/data/pembayaran', {
          ppdb_id: this.ppdb_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          let dataPembayaran = response.data.pembayaran;
          for (const b of dataPembayaran) {
            b.created_at = new Date(b.created_at).toLocaleString('id-ID', { dateStyle: 'long', timeStyle: 'medium' })
            if (b.updated_at) {
              b.updated_at = new Date(b.updated_at).toLocaleString('id-ID', { dateStyle: 'long', timeStyle: 'medium' })
            }
            this.dataBayar.riwayat.push(b);
            this.dataBayar.totalPembayaran += b.nominal;
          }
          this.dataBayar.riwayat.pop();
          this.dataBayar.pendaftar = dataPembayaran[dataPembayaran.length - 1];
          this.dataBayar.pendaftar.terbilang = this.$terbilang(this.dataBayar.pendaftar.nominal);
          if (Object(this.dataBayar.pendaftar).status_ppdb_id > 2) {
            this.dataBayar.seragam = true;
          }
          await this.getBiayaDu();
          await this.getListSeragam();
          this.pembayaranSiap = true;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        this.cetak();
      }, 500)
    },
    async getBiayaDu() {
      try {
        const response = await this.axios.post('/data/biaya-du', {
          ppdb_id: this.ppdb_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.dataBayar.biaya_du = response.data.biaya_du;
          this.dataBayar.voucher = response.data.pembawa_ppdb_id ? true : false;
          this.dataBayar.kekurangan = Math.max(0, this.dataBayar.biaya_du - this.dataBayar.totalPembayaran);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListSeragam() {
      try {
        const response = await this.axios.post('/list/seragam', {
          tahun_ajaran_id: this.taAktif.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          for(const s of response.data.seragam) {
            this.dataBayar.listSeragam.push(s.nama);
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getDataPernyataanById() {
      try {
        const response = await this.axios.post('/data/pernyataan', {
          sp_ppdb_id: this.sp_ppdb_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.pernyataan = response.data.pernyataan[0];
          this.pernyataan.created_at = new Date(this.pernyataan.created_at).toLocaleString('id-ID', { dateStyle: 'long', timeStyle: 'medium' })
          if (this.pernyataan.updated_at) {
            this.pernyataan.updated_at = new Date(this.pernyataan.updated_at).toLocaleString('id-ID', { dateStyle: 'long', timeStyle: 'medium' })
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        this.cetak();
      }, 500)
    },
    cetak() {
      let printContents = this.$refs.myElement.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      window.close();
    }
  },
}
</script>

<style></style>